<template>
  <div :class="{'ddtalk': bg === 'ddtalk', 'cetuschool': bg === 'cetuschool'}">
    <div class="container d-flex flex-column">
      <div class="row align-items-center justify-content-center g-0 min-vh-100">
        <div class="col-lg-5 col-md-8 py-8 py-xl-0" style="width: 600px;">
          <!-- Card -->
          <div class="card shadow">
            <!-- Card body -->
            <div class="card-body p-6">
              <div class="mb-4">
                <h1 class="mb-5 fw-bold">{{brand}} Teaching Center</h1>
                <!-- <div class="buttons">
                  <button type="button" class="btn btn-outline-white shadow-sm"><i class="bi-google me-2 text-danger"></i>Google</button>
                  <button type="button" class="btn btn-outline-white shadow-sm"><i class="bi-twitter me-2 text-info"></i>Twitter</button>
                  <button type="button" class="btn btn-outline-white shadow-sm"><i class="bi-facebook me-2 text-primary"></i>Facebook</button>
                </div> -->
              </div>
              
              <!-- divider -->
              <!-- <it-divider>OR</it-divider> -->

              <!-- Form -->
              <form ref="authForm" :class="{'was-validated': authFormValidated}" novalidate>
                <!-- Alert -->
                <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
                <!-- Email -->
                <div class="mb-3">
                  <label for="email" class="form-label">Email</label>
                  <div class="input-group">
                    <input ref="emailInput" v-model.trim="auth.email" type="email" id="email" class="form-control" name="email" placeholder="Email address" required @input="onEmailInput()">
                    <it-button kind="primary" class="rounded-end" outline style="width: 120px;" :loading="sendingOtp" :disabled="disableOtpButton" @click="doSendAuthOtp()">{{ optButtonText }}</it-button>
                    <div class="invalid-feedback" :class="{invalid: emailInputInvalid}">You must input valid email address.</div>
                  </div>
                </div>
                <!-- OTP -->
                <div class="mb-3">
                  <label for="otp" class="form-label">OTP</label>
                  <input v-model.trim="auth.otp" type="text" id="otp" class="form-control" name="otp" placeholder="One time password" required pattern="\d{6}" />
                  <div class="invalid-feedback">You must input valid otp.</div>
                </div>
                <!-- Button -->
                <div class="d-grid">
                  <it-button kind="primary" :loading="authing" @click="doAuth">Login</it-button>
                </div>
              </form>
            </div>
            <!-- Card Footer -->
            <div class="card-footer bg-white px-6 py-4">
              <p class="mb-0 text-center">By continuing you accept the <a href="#" class="text-inherit fw-semi-bold">Terms of Use</a> and <a href="#" class="text-inherit fw-semi-bold">Privacy Policy</a></p>
            </div>
          </div>

          <!-- Version -->
          <div class="text-center mt-3" style="color: gainsboro;">- {{ version }} -</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import Timer from 'tiny-timer'
import { computed, inject, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import config from '../../package.json'
import authAPI from '../api/auth'
import emailAPI from '../api/email'
import ItButton from '../components/ui/ItButton.vue'
import { toast } from '../injection'
import { Actions, useStore } from '../store'
import { TeacherAuthed, TeacherEmailOtpAuth } from '../types/auth'
import { AuthenticationError, Result } from '../types/common/result'

const brand = import.meta.env.VITE_APP_BRAND
const bg = brand.toLowerCase()
console.log(bg)
const version = computed(() => config.version)

const toastRef = inject(toast)

const router = useRouter()
const store = useStore()

const message = ref<string>('')

const auth = reactive<TeacherEmailOtpAuth>(new TeacherEmailOtpAuth())
const authForm = ref<HTMLFormElement>()

const optButtonText = ref<string>('Get OTP')
const disableOtpButton = ref<boolean>(false)
const sendingOtp = ref<boolean>(false)
let countDown = 30
const timer = new Timer({ interval: 1000, stopwatch: false })
timer.on('tick', () => {
  optButtonText.value = Number(countDown--).toString() + 's'
})
timer.on('done', () => {
  optButtonText.value = 'Get OTP'
  disableOtpButton.value = false
  countDown = 30
})
const emailInput = ref<HTMLInputElement>()
const emailInputInvalid = ref<boolean>(false)
const onEmailInput = function() {
  emailInput.value?.setCustomValidity('')
  emailInputInvalid.value = false
}

const doSendAuthOtp = function() {
  if (!emailInput.value?.checkValidity()) {
    emailInputInvalid.value = true
  }

  if (auth.email) {
    sendingOtp.value = true
    disableOtpButton.value = true
    emailAPI.sendAuthOtp(auth.email).then((response: AxiosResponse<Result<void>>) => {
      const result: Result<void> = response.data
      if (result.success) {
        toastRef?.value?.success('Send success')
        timer.start(countDown * 1000)
      } else {
        message.value = result.message
        disableOtpButton.value = false
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
      disableOtpButton.value = false
    }).finally(() => {
      sendingOtp.value = false
    })
  } else {
    emailInputInvalid.value = true
  }
}

const authing = ref<boolean>(false)
const authFormValidated = ref<boolean>(false)
const doAuth = function() {
  authFormValidated.value = true
  if (authForm.value?.checkValidity()) {
    authing.value = true
    // 登录或注册
    authAPI.emailOtpAuth(auth).then((response: AxiosResponse<Result<TeacherAuthed>>) => {
      const result = response.data
      if(result.success) {
        const authed = result.data
        const teacher = authed.teacher
        if (teacher.locked) { // 账户锁定
          message.value = 'The account is locked'
        } else {
            // 存储老师信息
          store.dispatch(Actions.AUTH, authed).then(() => {
            if (teacher.complete) {
              router.push({
                name: 'schedule'
              })
            } else {
              router.push({
                name: 'supplement'
              })
            }
          })
        }
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      const response = error.response
      if (response) {
        if (response.status === 400) {
          const authenticationError: AuthenticationError = response.data as AuthenticationError
          message.value = authenticationError.error_description
        } else {
          message.value = error.message
        }
      } else {
        toastRef?.value?.error(error.message)
      }
    }).finally(() => {
      authing.value = false
    })
  }
}
</script>

<style scoped>
.cetuschool {
  background-image: url("/images/cetuschool/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ddtalk {
  background-image: url("/images/ddtalk/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>